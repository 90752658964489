
import { TreeNode } from '../chordpro-parser';
import './jsx-formatter.css';

export const jsxFormatter = (tree: TreeNode<'main'>) => {
  return (
    <div>
      {tree.children.map(node => {
        switch (node.type) {
          case 'title':
            return (
              <h1>{node.children}</h1>
            );
          case 'subtitle':
            return (
              <h2>{node.children}</h2>
            );
          case 'text':
            if (node?.props?.chords) {
              return (
                <div className="chord-and-text-lines-container">
                  <pre>{node?.props?.chords}</pre>
                  <pre>{node.children}</pre>
                </div>
              );
            } else {
              return (<p>{node.children}</p>);
            }
          case 'chorus-section':
            return (
              <div className="chorus-section">
                {jsxFormatter(node)}
              </div>
            );
          case 'newline':
            return (<br />);
          default:
            return null;
        };
      })}
    </div>
  );
};
