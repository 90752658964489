// @ts-ignore
import { createCP, parseCP, transpose, keys } from 'simplechordpro';

////////////////////////////////////////////////////////////////////////////////
// NOTE: This is used for the old way (first implementation) of parsing the file
// once we replace all functionality in the new chordpro-parser.ts file, this
// should all be removed.



/**
 * Takes a ChordPro string and parses it, returning it in a formatted way.
 * 
 * @param str The ChordPro string to be parsed
 * @returns 
 */
export const parseChordPro = (str: string) => {
  // console.log(str.split('\n'));

  // str = transpose(str, "G", "C");
  // str = transpose(str, "G", "Solfège");
  // str = transpose(str, "G", "Nashville");
  // console.log('keys: ', keys);

  // parseChordProCustom(str);

  // str = decodeURIComponent(escape(str));
  const parsed: string = parseCP(str);
  // console.log('parsed: ', parsed.split('\n'));

  return parsed;
};

const getChordProObject = (str: string) => {
  const results = str.match(/{([^}]+)}/g)?.map(res => {
    res = res.replace(/{|}/g , '');

    if (res.includes(':')) {
      const [key, val] = res.split(':');
      return {
        key,
        value: val.trim(),
      }
    }

    return {
      key: res,
      value: undefined,
    }
  });

  // console.log('matches: ', results);
  return results;
};

const getSongChords = (str: string) => {
  const results = str.match(/\[(.*?)\]/g)?.map(b=>b.replace(/\[|\]/g, ''));
  return results;
};

export const getSongKey = (str: string) => {
  const chordProData = getChordProObject(str);

  let songKey;

  // Check if the key was set as ChordPro data and if so, use that as the
  // original key
  const chordProKey = chordProData?.find(data => data.key === 'key');
  if (chordProKey) {
    songKey = chordProKey.value;
  }

  // If key was not set explicitly then use the first chord key that was used.
  if (!songKey) {
    const songKeys = getSongChords(str);
    songKey = songKeys?.[0];
  }

  return songKey;
};

export const keyList = [
  'A',
  'A#',
  'Bb',
  'B',
  'C',
  'C#',
  'Db',
  'D',
  'D#',
  'Eb',
  'E',
  'F',
  'F#',
  'Gb',
  'G',
  'G#',
  'Ab',
] as const;


export const transposeChordType = (str: string, transposeKey: typeof keyList[number], originalKey: string) => {
  if (!originalKey) {
    // Error: Key not set
    console.error('Key not set');
    return str;
  }

  str = transpose(str, originalKey, transposeKey);

  // str = transpose(str, "G", "C");
  // str = transpose(str, "G", "Solfège");
  // str = transpose(str, "G", "Nashville");
  // console.log('keys: ', keys);
  return str;
};

