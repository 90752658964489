import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import { getPlaylist, PlaylistData } from "../../api/songbook";
import './playlist-view.css';

export const PlaylistView = () => {
  const [playlistData, setPlaylistData] = useState<PlaylistData>({name: '', lines: []});
  const params = useParams<{name: string}>();
  const playlistName = params.name

  useEffect(() => {
    if (playlistName) {
      getPlaylist(playlistName).then(data => data && setPlaylistData(data));
    }
  }, [playlistName]);

  return (
    <>
      <h1>{playlistData.name}</h1>

      {playlistData.notes && (
        <>
          <h2>Notes</h2>
          <pre>{playlistData.notes}</pre>
        </>
      )}
      
      <h2>Songs</h2>
      <ul className="playlist">
        {playlistData.lines.map(songName => (
          // TODO: make these link out to the song route to view a song
          <li key={songName}>
            <Link to={`/song/${songName}.pro`}>
              {songName}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
