import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSong } from "../../api/songbook";
import { parseChordProCustom, TreeNode } from "../../helpers/chordpro-parser";
import { getSongKey, keyList, parseChordPro, transposeChordType } from "../../helpers/chordpro.helpers";
import { jsxFormatter } from "../../helpers/jsx-formatter/jsx-formatter";
import './song-view.css';

export const SongView = () => {
  const [chordProString, setChordProString] = useState('')
  const [parsedData, setParsedData] = useState<TreeNode<'main'> | undefined>();
  const [originalSongKey, setOriginalSongKey] = useState('');
  const [formattedString, setFormattedString] = useState('');
  const [transposedKey, setTransposedKey] = useState('regular');
  const params = useParams<{name: string}>();
  const songName = params.name

  useEffect(() => {
    if (songName) {
      getSong(songName).then(data => {
        if (!data) {
          return;
        }

        setChordProString(data);
        setParsedData(parseChordProCustom(data));

        setOriginalSongKey(getSongKey(data) ?? '');
        setFormattedString(parseChordPro(data));
      });
    }
  }, [songName]);

  const handleKeyChange = (event?: React.ChangeEvent<HTMLSelectElement>) => {
    const value: any = event?.target.value ?? '';

    setTransposedKey(value);
    setFormattedString(parseChordPro(transposeChordType(chordProString, value, originalSongKey)));
  };

  return (
    <>
      <div className="song-view">
        {!!parsedData && jsxFormatter(parsedData)}
      </div>

      {!!chordProString ? (
        <>
          <hr/>
          <select value={transposedKey} onChange={handleKeyChange}>
            {keyList.map(key => (
              <option key={key} value={key}>{key}</option>
            ))}
          </select>

          <pre>{formattedString}</pre>
        </>
      ) : (
        <p>Song Data Not Available</p>
      )}
    </>
  );
};
