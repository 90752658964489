import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { SongList } from './song-list/song-list';
import { SongView } from './song-view/song-view';
import { PlaylistView } from './playlist-view/playlist-view';
import './app.css';

export const App = () => {
  return (
    <div className="app">
      <Router>
        <Link to={`/`} style={{color: 'white'}}>
          Songbook
        </Link>
        <Switch>
          <Route path="/song/:name">
            <SongView />
          </Route>
          <Route path="/playlist/:name">
            <PlaylistView />
          </Route>
          <Route path="/">
            <SongList />
          </Route>
        </Switch>
      </Router>
    </div>
  )
};
