// @ts-ignore
import { createCP, parseCP, transpose, keys } from 'simplechordpro';

export type TreeNode<T extends NodeType> = {
  type: NodeType;
  props?: {
    originalLine?: string;
    chords?: string;
    [key: string]: any;
  };
  children: T extends 'main' ? TreeNode<NodeType>[] : string;
};

type NodeType = 'main' | 'title' | 'subtitle' | 'tag' | 'text' | 'newline' | 'chorus-section';

export const parseChordProCustom = (str: string) => {
  const lines = str.split('\n');
  // console.log('lines', lines);
  
  const parsedObjects = parseLines(lines);

  const tree: any = {
    type: 'main',
    props: [],
    children: parsedObjects,
  };

  // console.log('TREE: ', tree);
  return tree; 
};

const parseLines = (lines: string[]) => {
  const parsedObjects = [];

  let currLineIndex = 0;
  while (currLineIndex < lines.length - 1) {
    const currLine = lines[currLineIndex];

    if (currLine.includes('{title:')) {
      // title
      parsedObjects.push(parseTitle(currLine));
    } else if (currLine.includes('{subtitle:')) {
      // subtitle
      parsedObjects.push(parseSubtitle(currLine));
    } else if (currLine.includes('{tag:')) {
      // tag
      parsedObjects.push(parseTag(currLine));
    } else if (currLine.trim() === '') {
      // newline
      parsedObjects.push({
        type: 'newline',
        props: {originalLine: currLine},
        children: '\n',
      })
    } else if (currLine.includes('{soc')) {
      // chorus section

      // find the index of the ending {eoc} tag
      const endIdx = lines.slice(currLineIndex).findIndex(line => line.includes('{eoc'));

      // Get only the lines for the chorus section
      const sectionLines = lines.slice(currLineIndex + 1, currLineIndex + endIdx);
      
      // recursion
      const sectionData: any = parseLines(sectionLines);

      parsedObjects.push({
        type: 'chorus-section',
        props: {originalLine: sectionLines},
        children: sectionData,
      });

      // readjust the currLineIndex to start at the end of this section.
      // NOTE: the code will add 1 at then end of the loop so don't worry about 
      // doing that here.
      currLineIndex = currLineIndex + endIdx;
    }  else {
      // Parse the text
      parsedObjects.push(parseText(currLine));
    }

    currLineIndex++;
  }

  return parsedObjects;
};

const parseTitle = (line: string): TreeNode<'title'> => {
  const idx = line.indexOf('{title:');
  const endIdx = line.indexOf('}');
  // NOTE: add + 7 to the idx since that is the str length of "{title:"
  const value = line.slice(idx + 7, endIdx).trim();

  return {
    type: 'title',
    props: {
      originalLine: line,
    },
    children: value
  };
};

const parseSubtitle = (line: string): TreeNode<'subtitle'> => {
  const idx = line.indexOf('{subtitle:');
  const endIdx = line.indexOf('}');
  const value = line.slice(idx + 10, endIdx).trim();

  return {
    type: 'subtitle',
    props: {
      originalLine: line,
    },
    children: value
  };
};

const parseTag = (line: string): TreeNode<'tag'> => {
  const idx = line.indexOf('{tag:');
  const endIdx = line.indexOf('}');
  const value = line.slice(idx + 5, endIdx).trim();

  return {
    type: 'tag',
    props: {
      originalLine: line,
    },
    children: value
  };
};

const parseText = (line: string): TreeNode<'text'> => {
  // Use the parseCP function from the simplechordpro lib, we should replace
  // this functionality with our own logic.
  const parsed: string = parseCP(line);

  if (parsed.includes('\n')) {
    const lines = parsed.split('\n');

    const node: TreeNode<'text'> = {
      type: 'text',
      props: {
        chords: lines[0],
        originalLine: line,
      },
      children: lines[1],
    };

    return node;
  } else {
    return {
      type: 'text',
      props: {
        originalLine: line,
      },
      children: parsed,
    };
  }
};

