import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSongList, Links } from '../../api/songbook';
import './song-list.css';

export const SongList = () => {
  const [links, setLinks] = useState<Links>({songs: [], playlists: []});

  useEffect(() => {
    const handleGetSongList = async () => {
      const links = await getSongList();

      if (!!links) {
        setLinks(links);
      }
    };

    handleGetSongList();
  }, []);

  return (
    <div className="song-list">
      <div>
        <h1>Songs</h1>
        <ul>
          {links.songs.map((link, index) => (
            <li key={index}>
              {/* slice the last four chars (i.e. ".pro" suffix) */}
              <Link to={`/song/${link}`}>
                {link.slice(0, -4)}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h1>Playlists</h1>
        <ul>
          {links.playlists.map((link, index) => (
            <li key={index}>
              {/* slice the last four chars (i.e. ".lst" suffix) */}
              <Link to={`/playlist/${link}`}>
                {link.slice(0, -4)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
};
