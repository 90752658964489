export const getSongList = async () => {
  try {
    // fetch all available songs and playlists
    const response = await fetch('/SongBook/');
    const data = await response.text();

    return parseSongListData(data)
  } catch (error) {
    console.log(error);
  }
};

export type Links = {
  songs: string[],
  playlists: string[]
};
const parseSongListData = (htmlString: string) => {
  // First create a DOM out of the html string;
  const doc = document.createElement('html');
  doc.innerHTML = htmlString;

  // query all anchor tags
  const anchorTags = doc.getElementsByTagName('a');

  // separate into lists for songs and playlists
  const links = Object.values(anchorTags).reduce<Links>((acc, anchorTag) => {
    const rawHref = anchorTag.href;

    // get just the file name portion of the href string (i.e. remove http://domain.com/)
    let fileName = rawHref.split('/')[3];
    fileName = decodeURIComponent(fileName);

    if (fileName.includes('.pro')) {
      // it is a song
      return {...acc, songs: [...acc.songs, fileName]};
    } else if (fileName.includes('.lst')) {
      // it is a playlist
      return {...acc, playlists: [...acc.playlists, fileName]};
    } else {
      return acc;
    }
  }, {songs: [], playlists: []});

  // console.log('links: ', links);
  return links;
};


export type PlaylistData = {
  name: string;  // The name of the playlist.
  notes?: string; // Playlist notes.
  lines: string[]; // Any other file lines (should be one song name per line).
};
const parsePlaylistData = (str: string): PlaylistData => {
  // NOTE: Playlist files seem to have the following structure:
  // first line of file => playlist title
  // second line of file => playlist notes (if there are any else just song name, starts with a '#' if so)
  // rest of file lines => song name (one new line for each song)

  // Split the string by new lines and decode each line.
  const strByLines = str.split('\n').map(line => decodeURIComponent(line));

  // Determine if the playlist has notes.
  // It seems notes are always the second line of the file (if any) and starts
  // with a '#' char.
  const playlistHasNotes = strByLines[1].startsWith('#');

  const playlistData = {
    name: strByLines[0],
    notes: playlistHasNotes ? strByLines[1].slice(1) : undefined,
    lines: playlistHasNotes ? strByLines.slice(2) : strByLines.slice(1),
  }

  return playlistData
};

export const getPlaylist = async (path: string) => {
  try {
    const response = await fetch(`/SongBook/${path}`);
    const data = await response.text();

    return parsePlaylistData(data);
  } catch (error) {
    console.log(error);
  }
};

export const getSong = async (path: string) => {
  try {
    const response = await fetch(`/SongBook/${path}`);
    const data = await response.text();

    return data;
  } catch (error) {
    console.log(error);
  }
};
